import update from 'immutability-helper';
import * as ProjectActions from '../modules/app/actions/project';
import * as FilesSortingActions from '../modules/files/actions/sorting';
import { FileEntity } from '../types/entities/file';
import * as FilesActions from '../modules/files/actions/files';
import * as FilesValidationActions from '../modules/files/actions/validation';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProjectDataState } from '../types/state';

const initialState: ProjectDataState = {};

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        saveSectionData: {
            reducer(state, action: PayloadAction<FileEntity[], string, string>) {
                state[action.meta] = action.payload;
            },
            prepare(files: FileEntity[], sectionHash: string) {
                return {
                    payload: files,
                    meta: sectionHash,
                };
            },
        },
    },
    extraReducers(builder) {
        builder.addCase(ProjectActions.addData, (state, action) => {
            return update(state, { $set: action.payload });
        });
        builder.addCase(FilesSortingActions.save, (state, action) => {
            update(state, {
                [action.payload]: {
                    $apply: (items: FileEntity[]) => {
                        return items.map((file) => {
                            const findItem = action.meta.find((item) => item.fileId === Number(file.id));
                            if (findItem) {
                                file.position = findItem.position;
                            }

                            return file;
                        });
                    },
                },
            });
        });
        builder.addCase(FilesActions.add, (state, action) => {
            return update(state, { [action.payload.section]: { $push: [action.payload] } });
        });
        builder.addCase(FilesActions.update, (state, action) => {
            const index = state[action.payload.section]?.findIndex((f: FileEntity) => f === action.payload);
            return update(state, {
                [action.payload.section]: {
                    [index]: {
                        $set: action.payload,
                    },
                },
            });
        });
        builder.addCase(FilesActions.remove, (state, action) => {
            return update(state, {
                [action.payload.section]: {
                    $set: (state[action.payload.section] as FileEntity[]).filter(
                        (file) => file.id !== action.payload.id,
                    ),
                },
            });
        });
        builder.addCase(FilesActions.addThumb, (state, action) => {
            const findIndexByFileId = (section: string, fileId: number): number => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return [section].findIndex((file: FileEntity) => file.id === fileId);
            };
            const index = findIndexByFileId(action.payload.section, action.payload.id);
            return index !== -1
                ? update(state, { [action.payload.section]: { [index]: { thumb: { $set: action.meta } } } })
                : state;
        });
        builder.addCase(FilesValidationActions.addErrors, (state, action) => {
            const findIndexByFileId = (section: string, fileId: number): number => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return state[section].findIndex((file: FileEntity) => file.id === fileId);
            };
            const index = findIndexByFileId(action.payload.section, action.payload.id);

            if (index === -1) {
                console.error('File not found', action.payload);
                return state;
            }

            return update(state, {
                [action.payload.section]: {
                    [index]: {
                        errors: { $set: action.meta },
                    },
                },
            });
        });
    },
});
export const { saveSectionData } = dataSlice.actions;
export default dataSlice.reducer;
