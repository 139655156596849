import * as React from 'react';
import forOwn from 'lodash-es/forOwn';
import { loadProject } from '../services/project';
import Loader from '../../../components/loader';
import { __ } from '../../../utils/i18n';
import * as ProjectActions from '../actions/project';
import * as AppActions from '../../../reducers/app-reducer';
import AppBar from './app-bar';
import ErrorInfo from './error-info';
import * as UploadListAction from '../../../reducers/upload-list-reducer';
import * as SelectedFiles from '../../../reducers/selected-files-reducer';
import * as SectionValidationActions from '../../../reducers/section-validation-reducer';
import { isProduction, isPreviewMode } from '../../../utils/helpers';
import { validateSection } from '../../sections/services/validation';
import ModalAcceptProject from './modal-accept-project';
import Exit from './exit';
import ModalPositionsAccept from './modal-accept-positions';
import '../assets/style.scss';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const Preview = React.lazy(() => import('../../preview/components/preview'));
const Main = React.lazy(() => import('./main'));

const AppMain = () => {
    const dispatch = useAppDispatch();
    const { completeLoadProject, errorLoadProject } = useAppSelector((state) => state.app);
    const { sectionValidation } = useAppSelector((state) => state);

    const [state, setState] = React.useState({
        showAcceptPositionsModal: false,
        showAcceptProjectModal: false,
        exitAppInProgress: false,
        projectValidationStatus: false,
    });

    const loadProjectData = async () => {
        try {
            const response = await loadProject();
            const sections = Object.keys(response.data);

            dispatch(ProjectActions.addConfig(response.sections));
            dispatch(ProjectActions.addInfo(response.info));
            dispatch(ProjectActions.addData(response.data));
            dispatch(UploadListAction.init(sections));
            dispatch(SelectedFiles.init(sections));
            dispatch(SectionValidationActions.init(sections));
            dispatch(AppActions.completeLoadProject());
        } catch (error) {
            dispatch(AppActions.errorLoadProject());
        }
    };
    const exitApp = () => {
        removeExitConfirm();
        setState({ ...state, exitAppInProgress: true, showAcceptProjectModal: false, showAcceptPositionsModal: false });
    };
    const renderContent = (): React.ReactNode => {
        const { exitAppInProgress, projectValidationStatus } = state;

        if (exitAppInProgress) {
            return <Exit validationStatus={projectValidationStatus} />;
        }

        if (errorLoadProject) {
            return <ErrorInfo />;
        }

        if (!completeLoadProject) {
            return <Loader title={__('Uruchamianie')} />;
        }

        if (isPreviewMode()) {
            return (
                <React.Suspense fallback={<Loader title={__('Uruchamianie')} />}>
                    <Preview />
                </React.Suspense>
            );
        }

        return (
            <React.Suspense fallback={<Loader title={__('Uruchamianie')} />}>
                <Main />
            </React.Suspense>
        );
    };
    const handleExit = () => {
        if (errorLoadProject || isPreviewMode()) {
            exitApp();
            return;
        }

        Object.keys(sectionValidation).map((item: string) => validateSection(item));
        let hasError = false;

        forOwn(sectionValidation, (value: string[]) => {
            if (value.length > 0) {
                hasError = true;
                return false;
            }
            return true;
        });

        hasError
            ? setState({ ...state, showAcceptProjectModal: true, projectValidationStatus: false })
            : setState({ ...state, showAcceptPositionsModal: true, projectValidationStatus: true });
    };

    React.useEffect(() => {
        if (isProduction()) {
            addExitConfirm();
        }

        if (!completeLoadProject) {
            loadProjectData();
        }
    }, []);

    React.useEffect(() => {
        Object.keys(sectionValidation).map((item: string) => validateSection(item));
    }, [completeLoadProject]);

    return (
        <>
            <AppBar onExit={handleExit} errorLoadProject={errorLoadProject} />
            {renderContent()}
            {state.showAcceptProjectModal ? (
                <ModalAcceptProject
                    onClose={() => setState({ ...state, showAcceptProjectModal: false })}
                    onExitApp={exitApp}
                />
            ) : null}
            {state.showAcceptPositionsModal ? (
                <ModalPositionsAccept
                    onClose={() => setState({ ...state, showAcceptPositionsModal: false })}
                    onExitApp={exitApp}
                />
            ) : null}
        </>
    );
};

const exitConfirmHandler = (e: BeforeUnloadEvent): string => {
    const confirmationMessage = __('Czy na pewno chcesz opuścić aplikację?');
    e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage;
};
const addExitConfirm = () => {
    window.addEventListener('beforeunload', exitConfirmHandler);
};
const removeExitConfirm = () => {
    window.removeEventListener('beforeunload', exitConfirmHandler);
};

export default AppMain;
