import Jed from 'jed';
import { TRANSLATIONS_URL } from './config';
import gettextParser from 'gettext-parser';
import { GetTextParserTranslations } from '../types/shared';

const TIMEOUT_INTERVAL = 10;
let loadStatus = false;
window.jedInstance = null;

const fetchAsyncBinaryTranslation = async (url: string, fn: (response: Buffer) => void) => {
    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer = await response.arrayBuffer();
        const buffer = Buffer.from(arrayBuffer);

        fn(buffer);
    } catch (error) {
        console.log('Fetch error:', error);

        loadStatus = true;
    }
};

const getText = (key: string): string => {
    const jedInst = window.jedInstance;

    if (!jedInst) {
        return key;
    }

    return jedInst.gettext(key);
};

export const translationLoader = (lang: string) => {
    try {
        if (!lang || lang === 'pl') {
            loadStatus = true;
            return;
        }

        const file = TRANSLATIONS_URL[lang];
        const refreshToken = new Date().getTime();

        const url = `${window.__UPLOADER_CONFIG__.api.replace('/api/v1', '')}/${file}?version=${refreshToken}`;

        fetchAsyncBinaryTranslation(url, (response: Buffer) => {
            const parseResponse = gettextParser.mo.parse(response);

            const parseResponseEntries = Object.entries(parseResponse.translations['']);

            const formattedTranslations = parseResponseEntries.reduce<GetTextParserTranslations>(
                (acc, [key, value]) => {
                    acc[key] = value.msgstr;
                    return acc;
                },
                {},
            );

            const locale_data = {
                messages: {
                    '': parseResponse.headers,
                    ...formattedTranslations,
                },
            };

            window.jedInstance = new Jed({
                locale_data,
                domain: 'messages',
            });

            loadStatus = true;
        });
    } catch (error) {
        console.log('Translations error', error);
    }
};

export const __ = (key: string, data: string[] | null = []) => {
    let message = getText(key);

    if (data && data.length) {
        const count = (message.match(/%s/g) || []).length;

        for (let i = 0; i <= count; i++) {
            message = message.replace(/%s/, data[i]);
        }
    }

    return message;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const whenTranslationLoaded = (callback: Function) => {
    if (!loadStatus) {
        setTimeout(() => {
            whenTranslationLoaded(callback);
        }, TIMEOUT_INTERVAL);
    } else {
        callback();
    }
};
